import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  getDocs,
  deleteDoc,
  addDoc,
  where,
  query,
  onSnapshot,
} from "firebase/firestore";

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
// };
const firebaseConfig = {
  apiKey: "AIzaSyDI7hLd1Hi6ECDewxsdWfImiuO7FGMLspo",
  authDomain: "prayerwallnew.firebaseapp.com",
  projectId: "prayerwallnew",
  storageBucket: "prayerwallnew.appspot.com",
  messagingSenderId: "307127280549",
  appId: "1:307127280549:web:960418f08c76a0c3ac7e4c"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export const DB = getFirestore(firebaseApp);
