import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Modal,
  TextField,
  Typography,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

import { addDoc, collection, getDocs, doc, updateDoc } from "firebase/firestore";

import { DB } from "./firebase";
import axios from "axios";

const PrayerWall = () => {
  const [prayers, setPrayers] = useState([]);
  const [open, setOpen] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");
  const recaptchaID = process.env.REACT_APP_RECAPTCHA_KEY
  
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    prayer: "",
    amountPrayed: 0
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const fetchPrayersFromFirestore = async (setPrayers) => {
    try {
      const prayerCollection = collection(DB, "prayerwall");
      const querySnapshot = await getDocs(prayerCollection);

      const prayersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setPrayers(prayersData);
    } catch (error) {
      console.error("Error fetching prayers from Firestore: ", error);
    }
  };

  useEffect(() => {
    fetchPrayersFromFirestore(setPrayers);
  }, []);

  const handleSubmit = async () => {
    try {
      // if (!captchaValue) {
      //   alert("Please complete the CAPTCHA before submitting.");
      //   return;
      // }
      const docRef = await addDoc(collection(DB, "prayerwall"), formData);
      setPrayers((prevPrayers) => [
        ...prevPrayers,
        { id: docRef.id, ...formData },
      ]);
      setOpen(false);
      setFormData({
        name: "",
        email: "",
        prayer: "",
      });

      setSnackbarOpen(true);
      fetchPrayersFromFirestore(setPrayers);
    } catch (error) {
      console.error("Error adding prayer: ", error);
    }
  };

 
const iPrayed = async (prayer) => {
  try {
    console.log(prayer,"prayer")
    const docRef = doc(DB, "prayerwall", prayer.id);

    await updateDoc(docRef, {
      amountPrayed: prayer.amountPrayed + 1,
    });

    console.log("Incremented 'amountPrayed' for document with ID:", prayer.id);
    fetchPrayersFromFirestore(setPrayers);
  } catch (error) {
    console.error("Error incrementing 'amountPrayed':", error);
  }
};


  return (
    <div>
       <Button
        style={{ backgroundColor: "#FE9391", color: "white" }}
        sx={{ mt: 3 }}
        variant="contained"
        onClick={() => setOpen(true)}
      >
        Submit a Prayer
      </Button>
      {open && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "50%",
            }}
          >
            <h2>Submit a Prayer</h2>
            <TextField
              sx={{ mb: 3 }}
              label="Name"
              fullWidth
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
            <TextField
              sx={{ mb: 3 }}
              label="Email"
              fullWidth
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
            <TextField
              sx={{ mb: 3 }}
              label="Prayer"
              fullWidth
              multiline
              rows={4}
              value={formData.prayer}
              onChange={(e) =>
                setFormData({ ...formData, prayer: e.target.value })
              }
            />
            {/* <ReCAPTCHA
              sitekey={recaptchaID}
              onChange={(value) => setCaptchaValue(value)}
            /> */}
            <Button
              style={{ backgroundColor: "#FE9391", color: "white" }}
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      )}

      <Grid container spacing={2} sx={{ mt: 3 }}>
      {prayers.map((prayer) => (
  <Grid
    item
    key={prayer.id}
    xs={4}
    sx={{ display: "flex", justifyContent: "center" }}
  >
    <Card
    onClick={()=> iPrayed(prayer)}
      sx={{
        width: "100%",
        position: "relative",
        "&:hover::before": {
          content: '"Click here to show ' + prayer.name.split(" ")[0] + ' that you\'re praying for them"',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          cursor:"pointer",
          justifyContent: "center",
          background: "rgba(0, 0, 0, 0.7)",
          color: "white",
          zIndex: 1,
          opacity: 1,
          transition: "opacity 0.3s ease-in-out",
        },
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "5px",
          backgroundImage: "linear-gradient(to right, #FFBB85, #FE9391)",
        }}
      ></div>
      <CardContent>
        <div >
          <strong> {prayer.prayer}</strong> <br></br>-{" "}
          {prayer.name.split(" ")[0]}
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "5px",
            right: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
{prayer.amountPrayed} 🙏
        </div>
      </CardContent>
    </Card>
  </Grid>
))}


      </Grid>
     
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent message="Prayer Submitted" />
      </Snackbar>
    </div>
  );
};

export default PrayerWall;
